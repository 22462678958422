import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Country, isPossiblePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../../helpers/constant";
import { getJobDiscovery } from "../../../helpers/posthog_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import { updateJobAppliedDialog } from "../../../redux/actions/dialog_action";
import { applyJob } from "../../../redux/actions/job_action";
import {
  updateCreateResumePopupStatus,
  updateInReviewResumePoupupStatus,
  updateNationalityDialog,
  updateRejectedResumePopupStatus,
  updateUserMobileNumberAndNationality,
} from "../../../redux/actions/user_action";
import * as jobTypes from "../../../redux/types/job_type";
import * as types from "../../../redux/types/user_type";
import {
  AutocompleteStyled,
  ButtonBox,
  DialogStyled,
  DividerStyled,
  ErrorText,
  IconButtonStyled,
  PhoneInputStyled,
  SubmitButton,
  Subtitle,
  SubtitleBox,
  TextFieldStyled,
  Title,
  TitleContainer,
  XMarkIconStyled,
} from "./styles";

type OptionType = {
  state: string;
};

function SharedNationalityDialog(props) {
  const { job, onApplyJobClicked, useLocalApply, origin } = props;

  const dispatch: any = useDispatch();
  const router = useRouter();
  const nationalities = constants.nationalities;
  const showNationalityDialog = useSelector(
    (state: any) => state.user?.showNationalityDialog
  );
  const stateRegions = useSelector((state: any) => state.jobs?.stateRegions);

  const viewHistoryState = useSelector(
    (state: any) => state.jobs.viewHistoryState
  );
  const jobListTab = useSelector((state: any) => state.jobs.jobListTab);
  const fypTab = Number(useSelector((state: any) => state.jobs.fypTab));

  const [isValidMobileNumber, setIsValidMobileNumber] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [nationality, setNationality] = useState<string>("");
  const [currentLocation, setCurrentLocation] = useState<string>(null);
  const [triggerSnackbarFunc] = useTriggerSnackbar();
  const user = useSelector((state: any) => state?.user?.user);

  const [geoLocationStr, setGeoLocationStr] = useState<Country>(
    ((typeof process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "string" &&
      process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase()) ??
      "MY") as Country
  );

  function getJobOrigin() {
    if (origin === "individual-job-page") {
      return router.query.origin ?? "individual_job_page";
    }
    if (origin === "job-list") {
      return getJobDiscovery(jobListTab, viewHistoryState, fypTab);
    }
  }

  const jobDiscoveryOrigin = getJobOrigin();

  function updateGeoLocation() {
    setGeoLocationStr(
      ((typeof process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "string" &&
        process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase()) ??
        "MY") as Country
    );
  }

  useEffect(() => {
    if (user?.mobile_number || user?.mobileNumber) {
      setMobileNumber(user?.mobile_number || user?.mobileNumber);
      handleMobileNumber(user?.mobile_number || user?.mobileNumber);
    }
    if (user?.nationality) {
      setNationality(user?.nationality);
    }
    if (user?.current_location || user?.currentLocation) {
      setCurrentLocation(user?.current_location || user?.currentLocation);
    }
  }, [job?.id, user]);

  function handleClose() {
    updateGeoLocation();
    setMobileNumber("");

    const params = {
      showNationalityDialog: false,
    };

    dispatch(updateNationalityDialog(params));
  }

  function handleMobileNumber(mobileNumber) {
    setMobileNumber(mobileNumber);

    let mobileNumberValidatedString = String(mobileNumber);

    if (isPossiblePhoneNumber(mobileNumberValidatedString)) {
      setIsValidMobileNumber(true);
    } else {
      setIsValidMobileNumber(false);
    }
  }

  function handleNationality(event, nationality) {
    setNationality(nationality);
  }

  function handleLocation(event, currentLocation) {
    setCurrentLocation(currentLocation ? currentLocation.state : null);
  }

  async function onSubmit() {
    if (isValidMobileNumber && nationality && mobileNumber && currentLocation) {
      sendTrackingEvent({
        event: "CE_click-next-nationality-popup",
      });

      const params = {
        mobileNumber: mobileNumber,
        nationality: nationality,
        currentLocation: currentLocation,
        email: user?.email,
        name: user?.name,
        freshGrad: user?.freshGrad,
      };

      setIsSaving(true);

      const updateNumAndNationalityRes = await dispatch(
        updateUserMobileNumberAndNationality(params)
      );

      const apiResUser = updateNumAndNationalityRes.payload;

      if (
        updateNumAndNationalityRes.type ===
        types.UPDATE_NATIONALITY_AND_MOBILE_NUMBER_SUCCESS
      ) {
        handleClose();

        if (useLocalApply === true) {
          applyJobWithCurrentState(job, apiResUser);
        } else {
          onApplyJobClicked(job, apiResUser);
        }

        triggerSnackbarFunc({
          snackbarMessage:
            "Mobile number, nationality and location updated successfully",
          severity: "success",
        });
      }

      setIsSaving(false);
    }
  }

  async function applyJobWithCurrentState(job: any, apiResUser: any) {
    // CONDITION - NO RESUME / RESUME NOT APPROVED
    // Do not send in application if user has no resume/rejected
    if (
      !apiResUser?.resume ||
      apiResUser?.state == "rejected" ||
      apiResUser?.state == "no_resume"
    ) {
      if (!apiResUser?.resume || apiResUser?.state == "no_resume") {
        dispatch(updateCreateResumePopupStatus(true));
      } else if (apiResUser?.state == "rejected") {
        dispatch(updateRejectedResumePopupStatus(true));
      }

      sendTrackingEvent({
        event: "CE_job-application",
        "job-title": job?.title ?? "",
        resume: false,
        logged_in: true,
        "job-bucket-id": null,
        scraped: job?.scraped ? true : false,
      });

      return;
    }

    // CONDITION - SCRAPED JOB
    if (job?.category === "scraped" || job?.category === "aggregated") {
      sendTrackingEvent({
        event: "CE_job-application",
        resume: true,
        logged_in: true,
        scraped: true,
        "job-bucket-id": null, // no bucket id due to not calling applyJob API
        "job-title": job?.title,
      });
      return window.open(job?.externalJobUrl, "_blank");
    }

    const response = await dispatch(
      applyJob({
        jobId: job?.id,
        origin: origin,
        jobDiscovery: jobDiscoveryOrigin,
      })
    );

    if (response.type === jobTypes.APPLY_JOB_FAILED) {
      triggerSnackbarFunc({
        snackbarMessage: response.errMsg ?? "Error",
        severity: "error",
      });
    } else if (response.type === jobTypes.APPLY_JOB_SUCCEED) {
      sendTrackingEvent({
        event: "CE_job-application",
        resume: true,
        logged_in: true,
        scraped: false,
        "job-bucket-id": response?.payload?.jobBucketId,
        "job-title": response?.payload?.appliedJob?.title,
      });

      if (apiResUser?.state == "in_review") {
        dispatch(updateInReviewResumePoupupStatus(true));
      } else {
        dispatch(updateJobAppliedDialog(true));
        triggerSnackbarFunc({
          snackbarMessage: "Application successful!",
          severity: "success",
        });
      }
    }
  }

  return (
    <DialogStyled open={showNationalityDialog ? true : false}>
      <TitleContainer>
        <Title>
          You're just seconds away from making your job application!
        </Title>
        <IconButtonStyled onClick={handleClose}>
          <XMarkIconStyled />
        </IconButtonStyled>
      </TitleContainer>
      <SubtitleBox>
        <Subtitle>
          These mandatory information are missing from your user profile. Please
          fill them in to continue.
        </Subtitle>
      </SubtitleBox>
      <DividerStyled />
      {showNationalityDialog ? (
        <PhoneInputStyled
          placeholder="Mobile Number"
          defaultCountry={geoLocationStr}
          value={mobileNumber}
          onChange={handleMobileNumber}
          countryCallingCodeEditable={false}
          // international
          smartCaret={true}
          disabled={isSaving}
          $error={
            !isValidMobileNumber && mobileNumber !== "" ? "true" : "false"
          }
        />
      ) : null}
      {!isValidMobileNumber && mobileNumber ? (
        <ErrorText>Invalid phone number</ErrorText>
      ) : null}
      <AutocompleteStyled
        id="nationality-select"
        options={nationalities}
        autoHighlight
        onChange={handleNationality}
        disabled={isSaving}
        value={user?.nationality ? user?.nationality : nationality}
        renderInput={(params) => (
          <TextFieldStyled {...params} label="Nationality" />
        )}
      />
      <AutocompleteStyled
        id="location-select"
        options={stateRegions}
        autoHighlight
        onChange={handleLocation}
        disabled={isSaving}
        value={
          user?.currentLocation
            ? user?.currentLocation
            : user?.current_location
            ? user?.current_location
            : currentLocation
        }
        getOptionLabel={(option: OptionType | string) => {
          if (typeof option === "string") {
            return option;
          }

          if (option && option.state) {
            return option.state;
          }

          return "";
        }}
        isOptionEqualToValue={(option: OptionType, value) =>
          option.state === value
        }
        renderInput={(params) => (
          <TextFieldStyled {...params} label="Location" />
        )}
      />
      <DividerStyled />
      <ButtonBox>
        <SubmitButton
          disabled={
            isSaving || !mobileNumber || !nationality
              ? true
              : false || !currentLocation
              ? true
              : false
          }
          onClick={onSubmit}
          variant="contained"
        >
          next
        </SubmitButton>
      </ButtonBox>
    </DialogStyled>
  );
}

export default SharedNationalityDialog;
